<template>
  <v-container fluid>
    <v-card class="px-5 py-3" :loading="loading">
      <!-- Title & Download Button -->
      <v-card-title class="d-flex align-center justify-space-between">
        <span>{{ $t('prescription') }}</span>
        <v-btn
          v-if="prescription.consultation.prescriptionPdfUrl"
          color="primary"
          :href="prescription.consultation.prescriptionPdfUrl"
          target="_blank"
          download
        >
          <v-icon left>mdi-download</v-icon>
          {{ $t('Download') }}
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Prescription Details -->
      <v-card-text>
        <v-list dense>
          <!-- Assigned User -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium text-grey-darken-1">
                {{ $t('Assigned to') }}
              </v-list-item-title>
              <v-chip class="mt-1" color="purple lighten-2" dark label>
                <v-icon left>mdi-account-circle-outline</v-icon>
                {{ user.username || $t('not_set') }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>

          <!-- Other Items -->
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium text-grey-darken-1">
                {{ item.name }}
              </v-list-item-title>
              <v-chip class="mt-1" color="blue-grey lighten-4" label>
                {{ item.value }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ShowPrescription",
  data() {
    return {
      items: [],
      loading: false,
      prescription: {
        consultation: {},
      },
      user: {}
    };
  },
  methods: {
    async fetchUser(userId) {
      if (!userId) return;
      try {
        const {data} = await this.$authApi.get(`/users/${userId}`);
        this.user = data.data;
      } catch (error) {
        console.warn("Error fetching user:", error);
      }
    },
    async fetchPrescription() {
      try {
        this.loading = true;
        const {data} = await this.$authApi.get(`/prescriptions/${this.$route.params.id}`);
        this.prescription = data;

        if (data.partner_user_id) {
          this.fetchUser(data.partner_user_id);
        }

        this.items = [
          {name: this.$t("request_date"), value: data?.transferred_at ?? this.$t("not_set")},
          {name: this.$t("phr_name"), value: data?.phr?.name ?? this.$t("not_set")},
          {name: this.$t("phr_phone"), value: data?.phr?.phone ?? this.$t("not_set")},
          {name: this.$t("tw_user_phone"), value: data?.tw_user?.phone ?? this.$t("not_set")},
          {name: this.$t("national_id"), value: data?.phr?.national_no ?? this.$t("not_set")},
          {name: this.$t("city"), value: data?.phr?.member?.city?.name ?? this.$t("not_set")},
          {name: this.$t("policy_number"), value: data?.tw_user?.policy_number ?? this.$t("not_set")},
          {name: this.$t("delivery_status"), value: data?.delivery_status?.name ?? this.$t("not_set")},
          {name: this.$t("rejection_reason"), value: data?.reason?.reason_text ?? this.$t("not_set")},
          {name: this.$t("delivery_date"), value: data?.delivery_date ?? this.$t("not_set")},
          {name: this.$t("approval_number"), value: data?.approval_number ?? this.$t("not_set")},
          {name: this.$t("partner_notes"), value: data?.partner_note ?? this.$t("not_set")}
        ];
      } catch (e) {
        if (e?.response?.status === 404) {
          this.$router.push({name: "prescriptions.list"});
        }
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchPrescription();
  },
};
</script>
